@import "../node_modules/bulma/bulma.sass";

body {
  background-color:#f3f3f4 {}
  margin: 0;
  padding: 10px;
  // font-family: -apple-system,'Helvetica Neue', sans-serif
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "open sans", "Helvetica Neue", Arial, sans-serif;
  color: #676a6c;
  min-height: 1080px; }

::-webkit-scrollbar {
	width: 3px;
	height: 3px; }
::-webkit-scrollbar-track {}
::-webkit-scrollbar-thumb {
	background-color: $primary; }

h1, h2, h3, h4, h5, h6 {
  font-weight: 100; }

h1 {
  font-size: 30px; }

h2 {
  font-size: 24px; }

h3 {
  font-size: 16px; }

h4 {
  font-size: 14px; }

h5 {
  font-size: 12px; }

h6 {
  font-size: 10px; }

h3, h4, h5 {
  margin-top: 5px;
  font-weight: 600; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }
.progress {
  height: 4px; }
.notification {
  position: absolute;
  right: -500px;
  top: 70px;
  z-index: 100;
  animation-name: example;
  animation-duration: 5s;
  animation-delay: 1s;
  animation-timing-function: ease; }
@keyframes example {
  0% {
    right: -500px;
    top: 70px; }

  25% {
    right: 5px;
    top: 70px; }

  50% {
    right: 5px;
    top: 70px; }

  75% {
    right: 5px;
    top: 70px; }

  100% {
    right: -500px;
    top: 70px; } }

.secondary-messages {
  color: $grey-light; }
.secondary-labels {
  color: $grey-light; }
.secondary-background {
  background-color: $grey-lightest; }
.lable-custom {
  width: 50%; }
.control-custom {
  width: 50%; }
.select-custom {
  width: 90%; }


.icon-box {
  display: flex;
  margin: 0 auto;
  padding-top: 5px;
  padding-left: 0px;
  padding-right: 0px;
  cursor: pointer; }

.datePickerBox {
  color: #676a6c !important; }
.subAmmountBox {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  width: 100%; }
.subAmountItem {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding-right: 7px; }

.commentBox {
  display: flex;
  width: 200px;
  height: 20px;
  overflow: hidden;
  flex-flow: row nowrap;
  justify-content: space-between; }
.commentValue {
  display: flex;
  width: 93%; }
.commentIcon {
  display: flex;
  width: 7%;
  padding-right: 0px; }


.accrualRow {
  &:hover {
    background-color: #f3f3f4;
    font-weight: bold; } }
// .modal-card
//   position: absolute
//   top: 50px
//   right: 100px
//   bottom: 0
//   left: 0
//   z-index: 10040
//   height: max-content
//   height: -moz-max-content
//   display: table
//   overflow: auto
